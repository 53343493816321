<template>
    <div class="emoji-picker">
      <div class="emoji-list">
        <button
          v-for="emoji in emojis"
          :key="emoji"
          @click="select(emoji)"
          class="emoji-btn"
        >
          {{ emoji.emoji }}
        </button>
      </div>
      <button class="btn btn-sm btn-danger mt-2" @click="$emit('close')">
        Закрити
      </button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
        return {
            emojis: [
                {
                    emoji: "🔥",
                    key: "fire"
                },
                {
                    emoji: "👍",
                    key: "thumbs_up"
                },
                {
                    emoji: "😊",
                    key: "smile"
                },
                {
                    emoji: "❤️",
                    key: "heart"
                },
                {
                    emoji: "🤣",
                    key: "laugh"
                },
                {
                    emoji: "😎",
                    key: "cool"
                },
                {
                    emoji: "😉",
                    key: "wink"
                },
                {
                    emoji: "🤯",
                    key: "mind_blown"
                },
                {
                    emoji: "😡",
                    key: "angry"
                },
                {
                    emoji: "👎",
                    key: "thumbs_down"
                },
                {
                    emoji: "🤩",
                    key: "starstruck"
                },
                {
                    emoji: "💩",
                    key: "poop"
                },
                {
                    emoji: "👌",
                    key: "ok"
                },
            ]
        };
    },
    methods: {
      select(emoji) {
        this.$emit("select", emoji.key);
      },
    },
  };
  </script>
  
  <style>
  .emoji-picker {
    background: #fff;
    border: 1px solid #cccccc4a;
    border-radius: 8px;
    padding: 10px;
    position: absolute;
    z-index: 10;
    box-shadow: 1px 2px 11px 0px rgb(56 65 74 / 19%);
  }
  .emoji-list {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  .emoji-btn {
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    border-radius: 6px;
  }
  button.emoji-btn:hover {
    background-color: #2a9cdb2e;
    transition: .5s;
    border-radius: 6px;
  }
  </style>
  