<!-- архівні завдання -->

<template>

    <!-- 
       Компонент таблиці
       - :dataParams - параметри до кнопки фільтру
       - :objParams - параметри для фільтрації таблиці 
       - :columns - конфігурація стовпців таблиці
       - :rows - масив з даними рядків таблиці
       - :pages - об'єкт із даними про пагінацію таблиці
       - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
       - :createdform - параметр-прапорець, що дозволяє чи забороняє створення нових записів у таблиці
       - - йде перевірка на права доступу 2000 (режим бога)
       - @create - подія, яка спрацьовує при створенні нового завдання на тестування
       - @open - подія, яка спрацьовує при відкритті картки завдання на тестування
       - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
       - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
   -->
   <tablecustom 
       :dataParams="dataParams"
       :objParams="objParams"
       @search="searchB" 
       :columns="columns" 
       :rows="rows" 
       :pages="objPages"
       :showpag="true"
       :createdform="this.perms[2000]" 
       @open="open" 
       @getdata="getdata"
       @changelimit="changelimit"
   />
   
</template>

<script>
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import { 
       mutateStatusTicket,
       mutatePriorityTicket
     } from '@/usabilityScripts/globalMutate.js'
import { storeS } from '@/store' // глобальне сховище даних

export default {
   props: ["objCard"],
   components: {
       tablecustom
   },
   data() {
       return {
           form: "",
           dataParams: {
               status: true,
               page: "testing"
           },
           objParams:{
               page: '1',
               pagelimit: '10',
               search: '',
               priority: '',
           },
           objPages: {
               links: [
                   {
                       page: 1,
                       current: "true"
                   }
               ],
               total: 4,
               page: 1,
               show: 4
           },
           columns: [
               {
                   name: "ID",
                   text: "id",
                   align: "left",
                   status: true
               },
               {
                   name: this.$t('Point_name'),
                   text: "taks",
                   align: "left",
                   status: true
               },
               {
                   name: this.$t('Status'),
                   text: "status",
                   align: "left",
                   status: true,
                   mutate: (item) => this.mutatestatus(item).name,
                   mutateClass: (item) => this.mutatestatus(item).color
               },
               {
                   name: this.$t('priority'),
                   text: "priority",
                   align: "left",
                   status: true,
                   mutate: (item) => this.mutatepriority(item).name,
                   mutateClass: (item) => this.mutatepriority(item).color
               },
               {
                   name: this.$t('Виконавець'),
                   text: "worker",
                   align: "left",
                   status: true
               },
               {
                   name: this.$t('Дата виконання'),
                   text: "deadline",
                   align: "left",
                   status: true
               },
               {
                   name: this.$t('Автор'),
                   text: "addedWorker",
                   align: "left",
                   status: true
               },
           ],
           rows: [
               {
                   "id": "taks-101",
                   "type": "task",
                   "taks": "Неможливо перевибрати знижку у вікні групи клієнтів",
                   "status": "jobs",
                   "priority": 3,
                   "worker": "Vadim Atamanyuk",
                   "deadline": "30 листопада, 2024",
                   "addedWorker": "Danya Latiy"
               }
           ]
       }
   },
   created(){
       // this.rows = this.objCard.rows
       
   },
   methods: {
       mutatepriority(e){
           // мутейт пріорітету
           return mutatePriorityTicket(e)
       },
       mutatestatus(e){
           // мутейт статусу
           return mutateStatusTicket(e)
       },
   },
   computed: {
       perms(){
           /*
               Повертає дані прав доступу користувача з глобального стору.
           */
           return storeS.perms
       },
       checks() {
           /*
               Повертає дані з налаштувань юзера з глобального стору.
           */
           return storeS.checks
       },
       user(){
           /*
               Повертає дані за користувача з глобального стору.
           */
           return storeS.userbase
       }
   },
}
</script>