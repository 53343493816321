<template>
  
    <tablecustom 
        @search="searchB" 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="true"
        @getdata="getdata"
        @open="open"
    />
  
    <!-- карточка тикета -->
    <viewbox 
        v-if="showModal" 
        @close="showModal = false" 
        :obj="obj" 
    ></viewbox>
  
</template>
  
<script>
import tablecustom from '@/components/globaltable/index';
import viewbox from '@/views/newtickets/view/index.vue'
import { Archive } from '@/API.js';
import { storeS } from "@/store";
import { mutateTypeTicket, 
        mutateWayproblem,
        mutateStatusTicket,
        mutateDecidedStatusTicket,
        mutatePriorityTicket,
        mutateProblemEnv,
        nameTag
    } from '@/usabilityScripts/globalMutate.js'

let apiArchive = new Archive();

export default {
    props: ["objCard"],
    components: {
      tablecustom,
      viewbox
    },
    data() {
      return {
        title: "archiveTickets",
        showModal: '',
        obj: {},
        objPages: {},
        dataParams: {
            status: true,
            page: "tickets"
        },
        objParams:{
            page: '1',
            pagelimit: '10',
            search: '',
            // status: '',
            priority: '',
            workerId: '',
            type: '',
            inJobWorkerId: '',
            pr1: '',
            pr2: ''
        },
        showFilterBox: false,
        othertabs: [],
        columns: [
          {
            name: this.$t("ID"),
            text: "id",
            align: "right",
            status: true
          },
          {
            name: this.$t("ticket"),
            text: "type",
            align: "left",
            mutate: (item) => this.mutateticket(item),
            status: true
          },
          {
            name: this.$t("ticket_name"),
            text: "ticketName",
            align: "left",
            status: true
          },
          {
            name: this.$t("problem_area"),
            text: "problemEnvironment",
            align: "left",
            mutate: (item) => item != null ? this.mutateProblemEnv(item) : '-',
            status: true
          },
          {
            name: this.$t("Status"),
            text: 'status',
            align: "left",
            status: true,
            mutate: (item) => this.mutateStatus(item).name,
            mutateClass: (item) => this.mutateStatus(item).color
          },
          {
            name: this.$t("Manager"),
            text: "closeWorkerName",
            align: "right",
            status: true
          },
          {
            name: this.$t("clients"),
            text: "userPhones",
            align: "right",
            mutate: (item) => item ? this.mutateNumber(item) : '-',
            status: false,
          },
        ],
        rows: [],
        infoblockShow: false,
        information: [
          {
              title: this.$t('totalTickets'),
              value: "0",
              icon: "ri-ticket-2-line",
              color: "secondary"
          },
        ]
      };
    },
    created() {
        this.getdata();
    },
    methods: {
        getdata(page){
            this.objParams.page = page != undefined ? page : '1';

            this.objParams.problemEnvironment = this.objCard.projectKey; // todo бек (немає фільтру)

            apiArchive.getAllArchiveTickets(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.objPages = result.data;
                    this.rows = result.data.items;
                }
            })
        },
        open(e){
            apiArchive.getArchiveTicket(e.id).then(result => {
                if(result.status == 'done'){
                    this.obj = result.data;
                    this.showModal = true;
                }
            })
        },
        changelimit: function(limit){
            this.objParams.pagelimit = limit;
            this.getdata();
        },
        searchB(e){
            this.objParams.search = e
            this.getdata();
        },
        changeFilter(name, value){
            if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            } else {
                this.objParams = {
                    page: '1',
                    pagelimit: '10',
                    search: '',
                    statusArchive: '',
                    taskName: '',
                    priority: '',
                    workerId: '',
                    pr1: '',
                    pr2: ''
                },
                this.getdata()
            }
        },
        mutateNumber(value){
            var r = []
            if(value != ''){
                for(var item in value){
                    r.push('<span class="fs-11 badge badge-soft-info">'+value[item]+'</span>')
                }
            }
            return r
        }, 
        mutateticket(e){
            return mutateTypeTicket(e)
        },
        mutatewayproblem(e){
            return mutateWayproblem(e)
        },
        mutateStatus(e){
            return mutateStatusTicket(e)
        },
        mutateDecidedStatus(e){
            return mutateDecidedStatusTicket(e)
        },
        mutatepriority(e){
            return mutatePriorityTicket(e)
        },
        mutateProblemEnv(e){
            return mutateProblemEnv(e)
        },
        nameTag(value){
            var r = []
            if(value != ''){
                for(var item in value){
                    r.push('<span class="fs-11 fw-semibold" style="background:' + nameTag(value[item]).color + ';border-radius:5px;padding:5px;color:white;">'+nameTag(value[item]).name+'</span>')
                }
            }
            return r
        }
    },
    mounted(){

    },
    computed: {
        user() {
            return storeS.userbase
        },
        perms() {
            return storeS.perms
        },
        checks() {
            return storeS.checks
        },
    },
};
</script>
  
<style scoped>
.tr_etner:hover {
    cursor: pointer;
    background: #f3f3f9;
}
.howto_block {
    margin-left:10px;
}
.howto_block i {
    font-size: 23px;
    opacity: 0.5;
}
.howto_block i:hover{
    opacity: 1;
}

.howto_block {
    position: relative;
    display: inline-block;
}

.howto_block .tooltiptext {
    visibility: hidden;
    position: absolute;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    border-radius: 6px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    margin-left: 10px;
}

.howto_block .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -7px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #555 transparent transparent;
}

.howto_block:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
</style>
  