<!-- сторінка проекту -->

<template>
    
    <!-- шапка проекту -->
    <headerProject
        :objCard="this.form"
        :isSkeleton="isSkeleton"
    />

    <b-row>
        <b-col lg="12">
            <div>

                <!-- Таби -->
                <tabsProject
                    :objCard="this.form"
                    @showCalendar="showCalendar = true"
                    @showTickets="showTickets = true"
                    @showWishes="showWishes = true"
                    @showUpdate="showUpdate = true"
                    @showTesting="showTesting = true"
                    @showBoard="showBoard = true"
                    @showArchiveTicket="showArchiveTicket = true"
                    @refreshTab="refreshTab"
                />

                <div class="tab-content pt-1 text-muted">

                    <!-- Зведення по проекту -->
                    <div class="tab-pane active show" id="summary-tab" role="tabpanel">
                        <tabSummary 
                            :key="tabKeys['summary']"
                            :objCard="this.form"
                        />
                    </div>

                    <!-- Дошка по проекту -->
                    <div class="tab-pane fade" id="board-tab" role="tabpanel" >
                        <tabBoard 
                            v-if="showBoard"
                            :key="tabKeys['board']"
                            :objCard="this.form"
                        />
                    </div>
                    
                    <!-- Список по проекту -->
                    <div class="tab-pane fade" id="list-tab" role="tabpanel" >
                        <tabList 
                            :objCard="this.form"
                        />
                    </div>

                    <!-- Календар -->
                    <div class="tab-pane fade" id="calendar-tab" role="tabpanel" >
                        <tabCalendar 
                            v-if="showCalendar"
                            :objCard="this.form"
                        />
                    </div>

                    <!-- Завдання -->
                    <!-- <div class="tab-pane fade" id="board-tab" role="tabpanel" >
                        <tabTasks 
                            :objCard="this.form"
                        />
                    </div> -->

                    <!-- Таб тестування -->
                    <div class="tab-pane fade" id="testing-tab" role="tabpanel" >
                        <tabTesting 
                            v-if="showTesting"
                            :key="tabKeys['testing']"
                            :objCard="this.form"
                        />
                    </div>

                    <!-- Архівні завдання -->
                    <div class="tab-pane fade" id="archiveTask-tab" role="tabpanel" >
                        <tabArchiveTasks 
                            :objCard="this.form"
                        />
                    </div>
                    
                    <!-- Таб проблеми (тікети) -->
                    <div class="tab-pane fade" id="problem-tab" role="tabpanel" >
                        <tabTickets 
                            v-if="showTickets"
                            :key="tabKeys['problem']"
                            :type="'problem'"
                            :objCard="this.form"
                        />
                    </div>

                    <!-- Таб побажання (тікети) -->
                    <div class="tab-pane fade" id="wishes-tab" role="tabpanel" >
                        <tabTickets 
                            v-if="showWishes"
                            :key="tabKeys['wishes']"
                            :type="'wishes'"
                            :objCard="this.form"
                        />
                    </div>

                    <!-- Таб архівні тікети -->
                    <div class="tab-pane fade" id="archiveticket-tab" role="tabpanel" >
                        <tabArchiveTicket 
                            v-if="showArchiveTicket"
                            :key="tabKeys['archive_ticket']"
                            :objCard="this.form"
                        />
                    </div>

                    <div class="tab-pane fade" id="update-tab" role="tabpanel" >
                        <!-- <tabTickets 
                            v-if="showWishes"
                            :type="'wishes'"
                            :objCard="this.form"
                        /> -->
                        <tabUpdate 
                            v-if="showUpdate"
                            :key="tabKeys['update']"
                            :objCard="this.form"
                        />
                    </div>

                </div>
            </div>

        </b-col>

    </b-row>

</template>

<script>
import headerProject from "./header.vue";
import tabsProject from "./tabs/index.vue"
import tabSummary from "./tabs/summary/index"
import tabBoard from "./tabs/board/index"
import tabList from "./tabs/list/index"
import tabCalendar from "./tabs/calendar/index"
import tabTasks from "./tabs/tasks/index"
import tabArchiveTasks from "./tabs/archive_tasks/index"
import tabTickets from "./tabs/tickets/index"
import tabUpdate from "./tabs/update/index"
import tabTesting from "./tabs/testing/index"
import tabArchiveTicket from "./tabs/archive_ticket/index"
import { developmentProjects } from '@/API.js';
import { storeS } from '@/store' // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new developmentProjects();

export default {
    components: {
        headerProject,
        tabsProject,
        tabSummary,
        tabBoard,
        tabList,
        tabCalendar,
        tabTasks,
        tabArchiveTasks,
        tabTickets,
        tabUpdate,
        tabTesting,
        tabArchiveTicket
    },
    data() {
        return {
            form: {},
            showCalendar: false,
            showTickets: false,
            showWishes: false,
            showUpdate: false,
            showTesting: false,
            showArchiveTicket: false,
            showBoard: false,
            activeTab: 'summary-tab', // Активний таб
            tabKeys: {
                summary: 0,
                calendar: 0,
                testing: 0,
                problem: 0,
                wishes: 0,
                update: 0,
                archive_ticket: 0,
                board: 0
            },
            isSkeleton: true, // стан завантаження
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        refreshTab(tabName) {
            console.log("refreshTab", tabName);
            
            if (this.tabKeys[tabName] !== undefined) {
                this.tabKeys[tabName]++;
            }
        },
        getdata(){
            // console.log("params > ", this.$route.params.id);

            apiServe.getProject(this.$route.params.id).then(result => {
                if(result.status == "done") {
                    this.form = result.data;
                    this.isSkeleton = false
                } else {
                    // Відображаємо помилку
                    this.switchError(result.error[0])
                }
            })
        },
        switchError(error){
            // відображення помилки

            switch (error) {

                case "project not found":
                    this.$toast.error(this.$t('projectIdnotFound'))
                    break;
            
                default:
                    this.$toast.error(error);

                    var errorText = 'Помилка відкриття картки проекту'; 
                    var statusRequest = result.status;
                    var api = 'getProject';
                    var fileRequest = 'src/views/development/projects/index.vue';
                    var params = id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));

                    break;
            }
        },
        getInitials(string) {
            // Отримання ініціалів
            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        },
    }
}
</script>

<style scoped>
.bg-wid-project {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 200px;
    background: rgb(3, 105, 161);
}
</style>