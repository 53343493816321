<!-- оновлення -->

<template>
    
    <tablecustom 
        @search="searchB" 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="true"
        :createdform="true" 
        @open="open" 
        @getdata="getdata"
        @changelimit="changelimit"
    />

</template>

<script>
import tablecustom from '@/components/globaltable/index';
import { Updates } from '@/API.js';
import { storeS } from '@/store.js'
import {mutateStatusUpdates} from '@/usabilityScripts/globalMutate'

let apiServe = new Updates();

export default {
    props: ["objCard"],
    components: {
        tablecustom,
    },
    data() {
        return {
            form: '',
            dataParams: {
                status: true,
                page: "changelog"
            },
            columns: [
                {
                    name: 'ID',
                    text: 'deadlineId',
                    align: 'text',
                    status: false
                },
                {
                    name: this.$t('project'),
                    text: "update",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("version"),
                    text: "version",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Deadline"),
                    text: "deadlineDate",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Status"),
                    text: "status",
                    align: "left",
                    mutate: (item) => this.mutateStatus(item).name,
                    mutateClass: (item) => this.mutateStatus(item).color,
                    status: true,
                },
                {
                    name: this.$t("created"),
                    text: "createWorkerName",
                    align: "left",
                    status: true,
                }
            ],
            objPages: '',
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                createWorkerId: '',
                update: 'dashboard',
                pr1: '',
                pr2: '',
                of: 'id', //deadline_date
                ot: 'desc' //asc
            },
            rows: [],
        }
    },
    created(){
        this.getdata();
        // console.log("objCard", this);
        
    },
    methods: {
        getdata(page){
            // Отримання списку оновлень

            this.objParams.page = page != undefined ? page : '1';
            this.objParams.update = this.objCard.projectKey;

            apiServe.getAllUpdates(this.objParams).then(result => {
                if(result.status === 'done') {
                    this.rows = result.data.items;
                    this.objPages = result.data;
                } else {
                    this.$toast.error(this.$t('error') + ` #370`);
                }
            })
        },
        mutateStatus(item) {
            return mutateStatusUpdates(item)
        },
    },
    computed: {
        perms() {
            return storeS.perms
        }
    },
}
</script>