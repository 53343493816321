<!-- Перегляд завдання -->

<template>
    
    <modal :title="'#'+form.id">
    <!-- <modal > -->

        <template v-slot:body>

            <b-row>

                <b-col lg="8">

                    <div class="card" >
                        <div class="card-body" >
                            <div class="text-muted" >
                                <h5 class="mb-3 fw-semibold text-uppercase">{{ form.title }}</h5>
                                <h6 class="mb-3 fw-semibold text-uppercase">Опис</h6>

                                <div class="desc_ql" v-html="form.desc"></div>

                                <div class="align-items-center d-flex" >
                                    <h4 class="card-title mb-0 flex-grow-1 fw-semibold">Дочірні завдання</h4>
                                    <div class="d-flex gap-1">

                                        <!-- Сортування сабтасок -->
                                        <div class="dropdown card-header-dropdown" >
                                            <a class="btn btn-soft-primary btn-sm" role="button" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Сортування {{ sortSubTasks != '' ? ': ' + sortSubTasks : '' }}
                                                <i class="mdi mdi-chevron-down align-middle ms-1"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-end"  style="">
                                                <a class="dropdown-item" @click="sortSubTasks = 'пріоритет'">Пріоритет</a>
                                                <a class="dropdown-item" @click="sortSubTasks = 'статус'">Статус</a>
                                                <a class="dropdown-item" @click="sortSubTasks = 'виконавець'">Виконавець</a>
                                                <div class="dropdown-divider" ></div>
                                                <a class="dropdown-item" @click="sortSubTasks = ''">За замовчуванням</a>
                                            </div>
                                        </div>

                                        <!-- Додавання сабтаски -->
                                        <button 
                                            type="button" 
                                            class="btn btn-soft-primary btn-sm"
                                            @click="toggleNewTaskInput"
                                        >
                                            +
                                        </button>

                                    </div>
                                </div>

                                <!-- Поле вводу нової задачі -->
                                <transition name="fade">
                                    <div v-if="showNewTaskInput" class="mt-3">
                                        <input
                                            type="text"
                                            ref="taskInput"
                                            class="form-control mb-2"
                                            placeholder="Що необхідно зробити?"
                                            v-model="newTaskTitle"
                                            @keydown.enter="addNewTask"
                                        />
                                        <div class="d-flex gap-2">
                                            <button
                                                class="btn btn-soft-success btn-md"
                                                @click="addNewTask"
                                            >
                                                Додати
                                            </button>
                                            <button
                                                class="btn btn-soft-danger btn-md"
                                                @click="toggleNewTaskInput"
                                            >
                                                Скасувати
                                            </button>
                                        </div>
                                    </div>
                                </transition>

                                <!-- Список сабтасок -->
                                <div 
                                    v-for="subtask in form.tasks"
                                    :key="subtask"
                                    class="card mb-1 card-animate copy subtask"
                                    @click="showTask = true"
                                >
                                    <div class="card-body">

                                        <div class="d-flex" style="align-items: center;">
                                            <div class="flex-grow-1" >

                                                <!-- id саб-таски -->
                                                <span class="text-muted">#{{ subtask.id}}</span>

                                            </div>

                                            <div class="flex-shrink-0" >
                                                <div class="d-flex gap-1 align-items-center" >

                                                    <span class="badge bg-danger">{{ subtask.priority }}</span>

                                                    <!-- Статус сабтаски -->
                                                    <span class="badge badge-soft-info">{{ subtask.status }}</span>

                                                    <!-- Хто виконує сабтаску -->
                                                    <div class="avatar-xs">
                                                        <div class="avatar-title bg-info rounded-circle">
                                                            {{ this.getInitials(subtask.worker) }}
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <!-- Коментарі, історія змін -->

                    <div class="card" >
                        <div class="card-body" >
                            <h5 class="mb-3 fw-semibold text-uppercase">Активність</h5>
                            <ul class="nav nav-pills arrow-navtabs nav-success bg-light mb-3" role="tablist" style="overflow-y: hidden;">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#arrow-all-activity" role="tab" aria-selected="true">
                                        <span class="d-block d-sm-none"><i class="mdi mdi-home-variant"></i></span>
                                        <span class="d-none d-sm-block">Всі</span>
                                    </a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" data-bs-toggle="tab" href="#arrow-comments-activity" role="tab" aria-selected="false" tabindex="-1">
                                        <span class="d-block d-sm-none"><i class="mdi mdi-account"></i></span>
                                        <span class="d-none d-sm-block">Коментарі</span>
                                    </a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" data-bs-toggle="tab" href="#arrow-history-activity" role="tab" aria-selected="false" tabindex="-1">
                                        <span class="d-block d-sm-none"><i class="mdi mdi-email"></i></span>
                                        <span class="d-none d-sm-block">Історія</span>
                                    </a>
                                </li>
                            </ul>
                            <!-- Tab panes -->
                            <div class="tab-content text-muted" >
                                <div class="tab-pane active show" id="arrow-all-activity" role="tabpanel" >
                                    <!-- Все по тасці (коментарі та історія) -->
                                    
                                    <template v-for="item in form.activities_all" :key="item">

                                        <div class="content mb-4 mt-2" >
                                            <div class="d-flex align-items-center" >
                                                <div class="flex-shrink-0" >
                                                    <div class="avatar-xs">
                                                        <div class="avatar-title bg-info rounded-circle">
                                                            {{ this.getInitials(item.user) }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1 ms-3" >
                                                    <h5 class="fs-15">{{ item.user }} {{ item.action }} <small class="text-muted fs-13 fw-normal">- {{ formatDateTime(item.date) }}</small> <span class="badge bg-soft-primary text-primary btn-sm"> тип запису</span></h5> 

                                                    <div class="d-flex gap-2" v-if="item.history">
                                                        <span>{{ item.history.from }}</span>
                                                        <span><i class="ri-arrow-right-line"></i></span>
                                                        <span>{{ item.history.to }}</span>
                                                    </div>

                                                    <span v-if="item.comment">
                                                        {{ item.comment }}
                                                    </span>

                                                </div>
                                            </div>
                                        </div>

                                    </template>

                                </div>
                                <div class="tab-pane" id="arrow-comments-activity" role="tabpanel" >
                                    <!-- Коментарі до таски -->
                                    
                                    <b class="d-block m-auto bg-danger text-white fs-18 p-4 mb-2">Тут має бути форма повідомлення todo</b>


                                    <template v-for="item in form.activities_comments" :key="item">

                                        <div class="content mb-4 mt-2" >
                                            <div class="d-flex align-items-center" >
                                                <div class="flex-shrink-0" >
                                                    <div class="avatar-xs">
                                                        <div class="avatar-title bg-info rounded-circle">
                                                            {{ this.getInitials(item.user) }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1 ms-3" >
                                                    <h5 class="fs-15">{{ item.user }} <small class="text-muted fs-13 fw-normal">- {{ formatDateTime(item.date) }}</small></h5>
                                                    <span>
                                                        {{ item.text }}
                                                    </span>
                                                    <!-- реакції -->
                                                    <div class="reactions mt-2 d-flex gap-2 align-items-center">
                                                        <span 
                                                            v-for="(count, key) in item.reactions" 
                                                            :key="key" 
                                                            class="reaction btn-sm btn-soft-info copy "
                                                            @click="addReaction(item, key)"
                                                        >
                                                            <span class="fs-14">{{ mutateEmoji(key) }}</span> <span style="font-weight: 500;">{{ count }}</span>
                                                        </span>
                                                        <button 
                                                            @click="toggleEmojiPicker(item)"
                                                            class="reaction btn-sm btn-soft-info fs-14"
                                                        >
                                                            <i class="bx bx-smile align-middle"></i>
                                                        </button>
                                                    </div>

                                                    <!-- Вікно вибору емодзі -->
                                                    <emoji-picker
                                                        v-if="item.showEmojiPicker"
                                                        @select="(emoji) => selectEmoji(item, emoji)"
                                                        @close="toggleEmojiPicker(item, false)"
                                                    />

                                                </div>
                                            </div>
                                        </div>

                                    </template>

                                </div>
                                <div class="tab-pane" id="arrow-history-activity" role="tabpanel" >
                                    <!-- Історія таски -->

                                    <template v-for="item in form.activities_history" :key="item">

                                        <div class="content mb-4 mt-2" >
                                            <div class="d-flex align-items-center" >
                                                <div class="flex-shrink-0" >
                                                    <div class="avatar-xs">
                                                        <div class="avatar-title bg-info rounded-circle">
                                                            {{ this.getInitials(item.user) }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1 ms-3" >
                                                    <h5 class="fs-15">{{ item.user }} {{ item.action }} <small class="text-muted fs-13 fw-normal">- {{ formatDateTime(item.date) }}</small></h5>
                                                    <div class="d-flex gap-2" v-if="item.newValue != null">
                                                        <span>{{ item.oldValue }}</span>
                                                        <span><i class="ri-arrow-right-line"></i></span>
                                                        <span>{{ item.newValue }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </template>

                                </div>
                            </div>

                        
                        </div>
                    </div>

                </b-col>

                <b-col lg="4">

                    <div class="accordion custom-accordionwithicon accordion-fill-info" id="accordionFill" >
                        <div class="accordion-item" >
                            <h2 class="accordion-header" id="accordionFillExample1">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accor_fill1" aria-expanded="true" aria-controls="accor_fill1">
                                    Загальне
                                </button>
                            </h2>
                            <div id="accor_fill1" class="accordion-collapse collapse show" aria-labelledby="accordionFillExample1" data-bs-parent="#accordionFill"  style="">
                                <div class="accordion-body" >
                                    
                                    <div class="mb-4" >
                                        <Multiselect v-model="form.status" :close-on-select="true" :searchable="true"
                                            :create-option="true" :options="[
                                                { value: '', label: 'Виберіть статус' },
                                                { value: 'to_performed', label: 'До виконання' },
                                                { value: 'in_work', label: 'В роботі' },
                                                { value: 'testing', label: 'Тестування' },
                                                { value: 'in_prerelease', label: 'В предрелізі' },
                                                { value: 'completed', label: 'Завершено' }
                                            ]" />
                                    </div>
                                    <div class="table-card" >
                                        <table class="table mb-0 fs-14">
                                            <tbody>
                                                <tr>
                                                    <td class="fw-medium">Виконавець</td>
                                                    <td>
                                                        <div class="d-flex align-items-center gap-2">
                                                            <div class="avatar-xs">
                                                                <div class="avatar-title fs-12 bg-info rounded-circle">
                                                                    {{ this.getInitials(form.performer) }} 
                                                                </div>
                                                            </div> 
                                                            <div class="flex-grow-1" >
                                                                <h5 class="fs-13 mb-0"><a class="text-body d-block">{{ form.performer }}</a></h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-medium">Автор</td>
                                                    <td>
                                                        <div class="d-flex align-items-center gap-2">
                                                            <div class="avatar-xs">
                                                                <div class="avatar-title fs-12 bg-warning rounded-circle">
                                                                    {{ this.getInitials(form.author) }} 
                                                                </div>
                                                            </div> 
                                                            <div class="flex-grow-1" >
                                                                <h5 class="fs-13 mb-0"><a class="text-body d-block">{{ form.author }}</a></h5>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-medium">Пріоритет</td>
                                                    <td><span class="badge bg-danger">Високий</span></td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-medium">Статус</td>
                                                    <td><span class="badge badge-soft-info">В роботі</span></td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-medium">Термін виконання</td>
                                                    <td>{{ formatDateTime(form.deadline) }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-medium">Дата створення</td>
                                                    <td>{{ formatDateTime(form.dateCreated) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </b-col>

            </b-row>


        </template>

        <!-- <template v-slot:footer-bottom >

            <button 
                type="submit"
                class="btn btn-info"
            >
                {{ $t('кнопка') }}
            </button>


        </template> -->

    </modal>


</template>

<script>
import modal from '@/components/modal/modal-lg'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import EmojiPicker from "./EmojiPicker.vue"; // Компонент для вибору емодзі
import { 
    mutateEmoji
} from '@/usabilityScripts/globalMutate.js'

export default {
    props: [],
    components: {
        modal,
        Multiselect,
        EmojiPicker
    },
    data() {
        return {
            showEmojiPicker: false,
            form: {
                id: "task-101",
                title: "Додати можливість обирати групу клієнтів для окремо обраних клієнтів через меню Дія", // завдання
                desc: "<p>Коли відміняєш знижку і натискаєш кнопку Додати то ця сама знижка не відображаються <br>Цей баг є тільки зі знижками в бонусах все працює ок <br>І ще чомусь оцей надпис про додавання не відображається зверху, тобто щоб його побачить треба робити скрол вниз, можливо спробувати його перенести вище<br>Проблема відтворюється як на ios так і на вінді </p>",
                status: "in_work", // дошка/статус
                performer: "Максим", // виконавець
                author: "Danya Latiy", // автор
                priority: "3", // пріоритет
                deadline: "2024-11-24 11:56:04", // термін виконання 
                dateCreated: "2024-10-20 12:11:50", // дата створення 
                tasks: [
                    {
                        "id": "task-201",
                        "title": "Не додається товар у пос-терміналі репорт 1",
                        "description": "",
                        "priority": "high",
                        "ticket": "3145",
                        "status": "До виконання",
                        "worker": "Maxim"
                    },
                    {
                        "id": "task-202",
                        "title": "Не додається товар у пос-терміналі репорт 2",
                        "description": "",
                        "priority": "high",
                        "ticket": "3145",
                        "status": "Тестується",
                        "worker": "Maxim"
                    }
                ],
                activities_all: [
                    {
                        "user": "Danya Latiy",
                        "action": "изменил Статус",
                        "date": "2024-11-27T15:56:00",
                        "history": {
                            "from": "To Do",
                            "to": "Testing"
                        }
                    },
                    {
                        "user": "Danya Latiy",
                        "action": "изменил Статус",
                        "date": "2024-11-25T15:56:00",
                        "history": {
                            "from": "Testing",
                            "to": "Done"
                        }
                    },
                    {
                        "user": "Danya Latiy",
                        "action": "обновил Решение",
                        "date": "2024-11-25T15:56:00",
                        "history": {
                            "from": "Нет",
                            "to": "Done"
                        }
                    },
                    {
                        "user": "Danya Latiy",
                        "action": "обновил Rank",
                        "date": "2024-11-25T15:56:00",
                        "history": {
                            "from": "Нет",
                            "to": "Ranked higher"
                        }
                    },
                    {
                        "user": "Vadim Atamanyuk",
                        "action": "добавил комментарий",
                        "date": "2024-11-29T16:03:00",
                        "comment": "Я вже все зробив, можна замінити, видалити без заміни і т.д. Але цій модалці займаюсь рефактором карточки лоялки тому заллю вже разом"
                    },
                    {
                        "user": "Danya Latiy",
                        "action": "добавил комментарий",
                        "date": "2024-11-29T14:40:00",
                        "comment": "Не зовсім зрозумів питання )"
                    },
                    {
                        "user": "Дмитрий Ланин",
                        "action": "добавил комментарий",
                        "date": "2024-11-28T12:38:00",
                        "comment": "Як бути якщо я хочу прибрати лоялку з групи?"
                    },
                    {
                        "user": "Vadim Atamanyuk",
                        "action": "добавил комментарий",
                        "date": "2024-11-28T12:29:00",
                        "comment": "Да, тепер для видалення обов'язково треба вибрати іншу лоялку"
                    },
                    {
                        "user": "Danya Latiy",
                        "action": "добавил комментарий",
                        "date": "2024-11-27T16:34:00",
                        "comment": "Я так зрозумів ви тут змінили логіку і тепер для видалення треба замінити бонусну програму. Якщо це так, тоді все працює ок. Зараз ще поклацаю як воно точно працює. Якщо щось знайду, то сюди відпишу."
                    },
                    {
                        "user": "Danya Latiy",
                        "action": "обновил Описание",
                        "date": "2024-11-22T16:24:00",
                        "history": {
                            "from": "Нет",
                            "to": "!image-20241122-142442.png|width=1184,height=839,alt=\"image-20241122-142442.png\"!"
                        }
                    },
                    {
                        "user": "Danya Latiy",
                        "action": "создал Задача",
                        "date": "2024-11-22T16:24:00",
                        "history": "История создания задачи"
                    }
                ],
                activities_comments: [
                    {
                        "user": "Vadim Atamanyuk",
                        "date": "2024-11-29T16:03:00",
                        "text": "Я вже все зробив, можна замінити, видалити без заміни і т.д. Але цій модалці займаюсь рефактором карточки лоялки тому заллю вже разом",
                        "reactions": {
                            "fire": 3,
                            "thumbs_up": 1
                        },
                    },
                    {
                        "user": "Danya Latiy",
                        "date": "2024-11-29T14:40:00",
                        "text": "Не зовсім зрозумів питання )",
                        "reactions": {}
                    },
                    {
                        "user": "Дмитрий Ланин",
                        "date": "2024-11-28T12:38:00",
                        "text": "Як бути якщо я хочу прибрати лоялку з групи?",
                        "reactions": {}
                    },
                    {
                        "user": "Vadim Atamanyuk",
                        "date": "2024-11-28T12:29:00",
                        "text": "Да, тепер для видалення обов'язково треба вибрати іншу лоялку",
                        "reactions": {}
                    },
                    {
                        "user": "Danya Latiy",
                        "date": "2024-11-27T16:34:00",
                        "text": "Я так зрозумів ви тут змінили логіку і тепер для видалення треба замінити бонусну програму. Якщо це так тоді все працює ок. Зараз ще поклацаю як воно точно працює. Якщо щось знайду то сюди відпишу.",
                        "reactions": {}
                    }
                ],
                activities_history: [
                    {
                        "user": "Danya Latiy",
                        "date": "2024-12-02",
                        "action": "изменил Статус",
                        "oldValue": "Testing",
                        "newValue": "Done",
                        "daysAgo": 5
                    },
                    {
                        "user": "Danya Latiy",
                        "date": "2024-12-02",
                        "action": "обновил Решение",
                        "oldValue": "Нет",
                        "newValue": "Done",
                        "daysAgo": 5
                    },
                    {
                        "user": "Danya Latiy",
                        "date": "2024-12-02",
                        "action": "обновил Rank",
                        "oldValue": "Нет",
                        "newValue": "Ranked higher",
                        "daysAgo": 5
                    },
                    {
                        "user": "Vadim Atamanyuk",
                        "date": "2024-11-27T15:56:00",
                        "action": "изменил Статус",
                        "oldValue": "To Do",
                        "newValue": "Testing"
                    },
                    {
                        "user": "Vadim Atamanyuk",
                        "date": "2024-11-27T15:56:00",
                        "action": "обновил Rank",
                        "oldValue": "Нет",
                        "newValue": "Ranked higher"
                    },
                    {
                        "user": "Дмитрий Ланин",
                        "date": "2024-11-27T13:14:00",
                        "action": "изменил Исполнитель",
                        "oldValue": "Не назначено",
                        "newValue": "Макс"
                    },
                    {
                        "user": "Danya Latiy",
                        "date": "2024-11-22T16:24:00",
                        "action": "обновил Описание",
                        "oldValue": "Нет",
                        "newValue": "!image-20241122-142442.png|width=1184,height=839,alt=\"image-20241122-142442.png\"!"
                    },
                    {
                        "user": "Danya Latiy",
                        "date": "2024-11-22T16:24:00",
                        "action": "создал Задача",
                        "oldValue": null,
                        "newValue": null
                    }
                ]
            },
            sortSubTasks: '',
            showNewTaskInput: false, // відображення блоку нового завдання
            newTaskTitle: '', // поле вводу
        }
    },
    created(){

    },
    methods: {
        toggleNewTaskInput() {
            // Відображаємо блок створення нового завдання

            this.showNewTaskInput = !this.showNewTaskInput;
            this.newTaskTitle = ''; // Очистка поля введення
            this.$nextTick(() => {
                const input = this.$refs.taskInput;
                input.focus();
            });
        },
        addNewTask() {
            if (this.newTaskTitle.trim()) {
                this.form.tasks.push({
                    id: this.form.tasks.length + 1,
                    title: this.newTaskTitle,
                    priority: 'Низький',
                    status: 'До виконання',
                    worker: 'Не назначено',
                });
                this.toggleNewTaskInput(); // Приховати поле введення
            }
        },
        getInitials(string) {
            // Отримання ініціалів
            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        },
        formatDateTime(dateTimeString){
            const months = [
                "січня", "лютого", "березня", "квітня", "травня", "червня",
                "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"
            ];
            const date = new Date(dateTimeString);
            const day = date.getDate();
            const month = months[date.getMonth()];
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");

            return `${day} ${month}, ${year}, в ${hours}:${minutes}`;
        },
        addReaction(item, emoji) {
            console.log("item", item);
            console.log("emoji", emoji);
            
            if (item.reactions[emoji]) {
                item.reactions[emoji]++;
            } else {
                // Просто додайте нове поле до реактивного об'єкта
                item.reactions[emoji] = 1;
            }
        },
        toggleEmojiPicker(item, state = null) {
            item.showEmojiPicker = state !== null ? state : !item.showEmojiPicker;
        },
        selectEmoji(item, emoji) {
            console.log("selectEmoji", emoji);
            console.log("item", item);
            
            this.addReaction(item, emoji);
            this.toggleEmojiPicker(item, false);
        },
        mutateEmoji(e){
            return mutateEmoji(e)
        }
    }
}
</script>