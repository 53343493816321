<!-- Календар -->

<template>

    <div class="card">
        <div class="card-body bg-soft-info">
            <div class="d-flex">
                <div class="flex-shrink-0">
                    <CalendarIcon
                        class="text-info icon-dual-info"
                    ></CalendarIcon>
                </div>
                <div class="flex-grow-1 ms-3">
                    <h6 class="fs-15">{{ $t('WelcometoYourCalendar') }}</h6>

                    <!-- <p class="text-muted mb-0">
                        {{ $t('EventThatApplicationsBook') }}
                    </p> -->
                </div>
                <div class="flex-grow-4 ms-3">
                    <!-- Фільтр -->
                    <!-- <Filter 
                        v-if="objParams && (dataParams && dataParams.status == true)" 
                        :dataParams="dataParams" 
                        :objParams="objParams" 
                        @changeFilter="changeFilter" 
                        @clearFilter="clearFilter"  
                    /> -->
                </div>
            </div>
        </div>
    </div>

    <b-row>
        <b-col lg="12">
            <b-card>
                <FullCalendar :options="calendarOptions" ref="fullCalendar"/>
            </b-card>
        </b-col>
    </b-row>

</template>

<script>
import { ref, onMounted } from "vue";
// імпорт бібліотек та компонентів для використання FullCalendar
import "@fullcalendar/core/vdom";
import { CalendarIcon } from "@zhuowenli/vue-feather-icons";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";

import FullCalendar from "@fullcalendar/vue3";
import ruLocale from '@fullcalendar/core/locales/ru';
import ukLocale from '@fullcalendar/core/locales/uk';

import { storeS } from '@/store' // глобальне сховище даних

export default {
    props: [],
    components: {
        FullCalendar,
        CalendarIcon,
    },
    data() {
        return {
            form: '',
            calendarOptions: { // параметри для відображення календрарю
                locale: ukLocale, // локалізація
                events: [], // масив подій
                start: null,
                end: null,
                activeDate: null,
                plugins: [
                    dayGridPlugin, // плагін перегляду подій по дням
                    timeGridPlugin, // плагін перегляду події по годинам
                    interactionPlugin, // плагін для відслідковування кліків, розмірів та інше
                    bootstrapPlugin, // тема календаря
                    listPlugin, // плагін перегляд списку відображає події у простому вертикальному списк
                ],
                themeSystem: "bootstrap", // тема календаря
                headerToolbar: { // // кнопки для перемикання між переглядами
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                },
                windowResize: () => { // автоматична адаптація під потрібний розмір
                    "dayGridMonth";
                },
                initialView: "dayGridMonth", // початковий вигляд календаря
                initialEvents: [],
                editable: true, // прапорець чи можна змінювати події в календарі
                selectable: true, // прапорець щоб дозволити користувачу робити вибір, клацаючи та перетягуючи
                selectMirror: true,
                dayMaxEvents: true, 
                weekends: true, // вихідні дні
                dateClick: this.dateClicked, // запускається коли користувач натискає на дату або час
                eventClick: this.openEvent, // запускається коли користувач натискає на подію
                eventChange: this.moveEvent, // викликається після певної зміни події
                aspectRatio: 2.6,
            },
        }
    },
    created(){
        // ... ініціалізація компонента при створенні
        if(storeS.lang == 'uk'){
            this.calendarOptions.locale = ukLocale
        } else if(storeS.lang == 'ru'){
            this.calendarOptions.locale = ruLocale
        }

        // отримання даних для календаря
        // this.getdata()
    },
    methods: {
        getInitialView() {
            /**
                * Метод для отримання початкового вигляду календаря.
                * @returns {string} - Початковий вигляд календаря залежно від ширини вікна.
            */

            if (window.innerWidth >= 768 && window.innerWidth < 1200) {
                return "timeGridWeek";
            } else if (window.innerWidth <= 768) {
                return "listMonth";
            } else {
                return "dayGridMonth";
            }
        },
        
    },
    mounted(){
        console.log("Height:");
    }
}
</script>
