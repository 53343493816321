<!-- Таби -->

<template>
    
    <div class="d-flex">

        <ul class="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">

            <li class="nav-item">
                <b-link class="nav-link fs-14 active" data-bs-toggle="tab" href="#summary-tab" @click="refreshTab('summary')" role="tab">
                    <span class=" d-md-inline-block fw-semibold">{{ $t('Summary') }}</span>
                </b-link>
            </li>

            <li class="nav-item">
                <b-link class="nav-link fs-14" data-bs-toggle="tab" href="#board-tab" @click="$emit('showBoard');refreshTab('board')" role="tab">
                    <span class=" d-md-inline-block fw-semibold">{{ $t('Board') }}</span>
                </b-link>
            </li>

            <li class="nav-item">
                <b-link class="nav-link fs-14" data-bs-toggle="tab" href="#list-tab" role="tab">
                    <span class=" d-md-inline-block fw-semibold">{{ $t('List') }}</span>
                    <span class="badge bg-soft-light fs-13" style="margin-left: 6px;">2</span>
                </b-link>
            </li>

            <li class="nav-item">
                <b-link class="nav-link fs-14" data-bs-toggle="tab" href="#calendar-tab" @click="$emit('showCalendar')" role="tab">
                    <span class=" d-md-inline-block fw-semibold">{{ $t('Calendar') }}</span>
                </b-link>
            </li>

            <li class="nav-item">
                <b-link class="nav-link fs-14" data-bs-toggle="tab" href="#testing-tab" @click="$emit('showTesting');refreshTab('testing')" role="tab">
                    <span class=" d-md-inline-block fw-semibold">{{ $t('Testing') }}</span>
                    <span class="badge bg-soft-light fs-13" style="margin-left: 6px;">10</span>
                </b-link>
            </li>

            <li class="nav-item">
                <b-link class="nav-link fs-14" data-bs-toggle="tab" href="#archiveTask-tab" role="tab">
                    <span class=" d-md-inline-block fw-semibold">{{ $t('ArchiveTasks') }}</span>
                </b-link>
            </li>

            <div class="hr_vr"></div>

            <li class="nav-item">
                <b-link class="nav-link fs-14" data-bs-toggle="tab" href="#problem-tab" @click="$emit('showTickets');refreshTab('problem')" role="tab">
                    <span class=" d-md-inline-block fw-semibold">{{ $t('Problems') }}</span>
                </b-link>
            </li>

            <li class="nav-item">
                <b-link class="nav-link fs-14" data-bs-toggle="tab" href="#wishes-tab" @click="$emit('showWishes');refreshTab('wishes')" role="tab">
                    <span class=" d-md-inline-block fw-semibold">{{ $t('wish_to_develop') }}</span>
                </b-link>
            </li>

            <li class="nav-item">
                <b-link class="nav-link fs-14" data-bs-toggle="tab" href="#archiveticket-tab" @click="$emit('showArchiveTicket');refreshTab('archive_ticket')" role="tab">
                    <span class=" d-md-inline-block fw-semibold">{{ $t('archive') }}</span>
                </b-link>
            </li>

            <div class="hr_vr"></div>

            <li class="nav-item">
                <b-link class="nav-link fs-14" data-bs-toggle="tab" href="#update-tab" @click="$emit('showUpdate');refreshTab('update')" role="tab">
                    <span class=" d-md-inline-block fw-semibold">{{ $t('Updates') }}</span>
                </b-link>
            </li>

            <!-- <div class="hr_vr"></div>

            <li class="nav-item">
                <b-link class="nav-link fs-14" data-bs-toggle="tab" href="#documentation-tab" role="tab">
                    <span class=" d-md-inline-block fw-semibold">Документація</span>
                </b-link>
            </li> -->

        </ul>

    </div>

</template>

<script>
export default {
    data() {
        return {
            form: '',
        }
    },
    methods: {
        refreshTab(tabName) {
            this.$emit('refreshTab', tabName);
        }
    }
}
</script>

<style scoped>
.hr_vr {
    border-left: 1px solid #ffffff;
    opacity: 0.4;
}
ul.nav::-webkit-scrollbar {
  height: 5px; /* Висота скролбару */
}

ul.nav::-webkit-scrollbar-thumb {
  background: #888a99; /* Колір "повзунка" */
  border-radius: 0px; /* Заокруглення */
}

ul.nav::-webkit-scrollbar-thumb:hover {
  background: #888a99; /* Колір при наведенні */
}

ul.nav::-webkit-scrollbar-track {
  background: #f3f3f8; /* Колір фону треку */
  border-radius: 15px;
}
</style>