<!-- дошка -->

<template>
    
    <b-card no-body>
        <b-card-body>
            <b-row class="g-2">

                <!-- Пошук по дошці -->
                <b-col lg="3" class="col-auto">
                    <div class="search-box">
                        <input type="text" class="form-control search" placeholder="Пошук по дошці">
                        <i class="ri-search-line search-icon"></i>
                    </div>
                </b-col>
                
                <!-- Фільтр по співробітникам та активація додаткового доступу -->
                <div class="col-auto ms-sm-auto">
                    <div class="avatar-group" id="newMembar">

                        <!-- Співробітники які мають доступ до проекту -->
                        <template v-for="item in workers" :key="item">
                            <b-link class="avatar-group-item" v-b-tooltip.hover
                                :title="item.workerName">
                                <div class="avatar-xs">
                                    <div class="avatar-title bg-info rounded-circle">
                                        {{ this.getInitials(item.workerName) }}
                                    </div>
                                </div>
                            </b-link>
                        </template>

                        <!-- Надати доступ до проекту, виклик вікна todo -->
                        <b-link class="avatar-group-item"
                            @click="modalShow = !modalShow">
                            <div class="avatar-xs">
                                <div class="avatar-title rounded-circle">
                                    +
                                </div>
                            </div>
                        </b-link>

                    </div>
                </div>
            </b-row>
        </b-card-body>
    </b-card>

    <!-- Дошка  -->
    <div class="tasks-board mb-3" id="kanbanboard">

        <draggable
            v-model="columns" 
            group="columns" 
            @change="onColumnChange" 
            class="columns-container"
        >
            <!-- Колонки kanban -->
            <template v-for="column in columns" :key="column">

                <div class="tasks-list">

                    <!-- Шапка колонки -->
                    <div class="d-flex mb-2" style="align-items: center">
                        <div class="flex-grow-1">
                            <!-- Назва колонки та кількість тасок -->
                            <h6 class="fs-14 text-uppercase fw-semibold mb-0">
                                {{ column.name }} 
                                <b-badge tag="small" variant="success" class="align-bottom ms-1 fs-11" :style="`background-color:${column.color}!important`" >{{ column.tasks.length }}</b-badge>
                            </h6>
                        </div>
                        <div class="flex-shrink-0">
                            <div class="dropdown">
                                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-12" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="ri-more-2-fill align-bottom"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-end">
                                    <a class="dropdown-item" href="#">{{ $t('toEditAp') }}</a>
                                    <a class="dropdown-item" href="#">{{ $t('Delete') }}</a>
                                </div>
                            </div>
                        </div>
                    </div>

                        <div class="tasks-wrapper px-3 mx-n3" style="height: auto;overflow: hidden scroll;">
                            <div id="task" :class="`tasks ${column.tasks && column.tasks.length ? '' : 'noTask'}`">
                                <draggable :list="column.tasks" class="dragArea" group="tasks" @change="onTaskChange">
                                    <!-- <b-card no-body class="tasks-box card-animate" v-for="task of column.tasks" :key="index" style="margin-top: 10px;" @click="showTask = true"> -->
                                    <b-card no-body class="tasks-box card-animate" v-for="task of column.tasks" :key="index" style="margin-top: 10px;">
                                        <b-card-body>
                                            <div class="d-flex mb-2">
                                                <h6 class="fs-15 mb-0 flex-grow-1 text-truncate title-task copy" @click="showTask = true">
                                                    <!-- Назва завдання -->
                                                    <span>{{ task.title }}</span>
                                                </h6>
                                                <div class="dropdown">

                                                    <!-- Дії з таскою -->
                                                    <b-link 
                                                        class="text-muted" 
                                                        id="dropdownMenuLink1"
                                                        data-bs-toggle="dropdown" 
                                                        aria-expanded="false"
                                                    >
                                                        <i class="ri-more-fill"></i>
                                                    </b-link>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink1">

                                                        <!-- Відкрити завдання todo -->
                                                        <li>
                                                            <b-link class="dropdown-item">
                                                                <i class="ri-eye-fill align-bottom me-2 text-muted"></i> 
                                                                Відкрити
                                                            </b-link>
                                                        </li>

                                                        <!-- Редагувати завдання todo -->
                                                        <li>
                                                            <b-link class="dropdown-item">
                                                                <i class="ri-edit-2-line align-bottom me-2 text-muted"></i>
                                                                {{ $t('toEditAp') }}
                                                            </b-link>
                                                        </li>

                                                        <!-- Видалити завдання todo -->
                                                        <li>
                                                            <b-link class="dropdown-item" @click="deleteTask">
                                                                <i class="ri-delete-bin-5-line align-bottom me-2 text-muted"></i>
                                                                {{ $t('Delete') }}
                                                            </b-link>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center">

                                                <!-- Пріоритет завдання -->
                                                <div class="flex-grow-1">
                                                    <b-badge 
                                                        variant="soft-danger" 
                                                        class="badge-soft-danger me-1"
                                                    >
                                                        Високий
                                                    </b-badge>
                                                </div>

                                                <!-- Співробітники які працюють на завданням -->
                                                <div class="flex-shrink-0" v-if="task.users && task.users.length">
                                                    <div class="avatar-group">
                                                        <b-link  
                                                            v-for="(item, index) of task.users"
                                                            :key="index" 
                                                            class="avatar-group-item" 
                                                            v-b-tooltip.hover
                                                            :title="item.workerName"
                                                        >
                                                            <div class="avatar-xs">
                                                                <div class="avatar-title bg-info rounded-circle">
                                                                    {{ this.getInitials(item.workerName) }}
                                                                </div>
                                                            </div>
                                                        </b-link>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Прогрес-бар -->
                                            <div class="mt-auto pt-3" v-if="task.tasksCount">
                                                <div class="d-flex mb-2">
                                                    <div class="flex-grow-1">
                                                        <div>{{ $t('task') }}</div>
                                                    </div>
                                                    <div class="flex-shrink-0">
                                                        <div>
                                                            <i class="ri-list-check align-bottom me-1 text-muted"></i> {{ task.tasksCount.new }}/{{ task.tasksCount.all }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="progress progress-sm animated-progress">
                                                    <div class="progress-bar bg-success" role="progressbar" :aria-valuenow="task.tasksCount.new" aria-valuemin="0" aria-valuemax="100" :style="`width: ${task.tasksCount.progressPercent}%;`"></div>
                                                </div>
                                            </div>

                                            <!-- Саб-таски -->
                                            <div class="hstack gap-2 flex-wrap mt-2 mb-2" v-if="task.tasks && task.tasks.length > 0">
                                                <button type="button" class="btn btn-soft-info"  data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="true" aria-controls="collapseExample">
                                                    <span>0/2 <i class="ri-arrow-right-s-line align-bottom"></i></span>
                                                </button>
                                            </div>

                                            <!-- Картки саб-тасок -->
                                            <div class="collapse" id="collapseExample">
                                                <div 
                                                    v-for="subtask in task.tasks"
                                                    :key="subtask"
                                                    class="card mb-1 card-animate copy subtask"
                                                    @click="showTask = true"
                                                >
                                                    <div class="card-body">

                                                        <div class="d-flex" style="align-items: center;">
                                                            <div class="flex-grow-1" >

                                                                <!-- id саб-таски -->
                                                                <span class="text-muted">#{{ subtask.id}}</span>

                                                            </div>

                                                            <div class="flex-shrink-0" >
                                                                <div class="d-flex gap-1 align-items-center" >

                                                                    <!-- Статус сабтаски -->
                                                                    <span class="badge badge-soft-info">{{ subtask.status }}</span>

                                                                    <!-- Хто виконує сабтаску -->
                                                                    <div class="avatar-xs">
                                                                        <div class="avatar-title bg-info rounded-circle">
                                                                            {{ this.getInitials(subtask.worker) }}
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </b-card-body>

                                        <!-- Футер картки таски -->
                                        <b-card-footer class="border-top-dashed">
                                            <div class="d-flex">
                                                <div class="flex-grow-1">
                                                    <!-- Id таски -->
                                                    <h6 class="text-muted mb-0 fs-12">#{{ task.id }}</h6>
                                                </div>

                                                <!-- Відображення того, що завдання створено по тікету -->
                                                <div v-if="task.ticket" class="flex-shrink-0">
                                                    <b-badge 
                                                        variant="soft-info" 
                                                        class="badge-soft-info me-1"
                                                    >
                                                        По тікету
                                                    </b-badge>
                                                </div>

                                            </div>
                                        </b-card-footer>
                                    </b-card>

                                    <!-- Додати нове замовлення -->
                                    <div class="my-3" v-if="showBlockAddedTask == false && column.name != selectBlockAdded">
                                        <b-button 
                                            variant="soft-info" 
                                            class="w-100" 
                                            @click="showTaskInput(column.name)"
                                        >
                                            Нове завдання
                                        </b-button>
                                    </div>
                                    <div v-if="selectBlockAdded == column.name">
                                        <div class="card tasks-box card-animate" style="margin-top: 10px;" >
                                            <div class="card-body" >
                                                <span class="fs-14 text-muted d-block mb-2">Що потрібно зробити?</span>
                                                <textarea 
                                                    class="form-control"
                                                    ref="taskInput" 
                                                    @keydown.enter="addTask"
                                                    v-model="newTaskContent"
                                                    @blur="handleBlur"
                                                ></textarea>
                                            </div>
                                            <div class="card-footer border-top-dashed" style="padding: 10px 1rem;">
                                                <div class="d-flex" style="flex-direction: row-reverse;">
                                                    <button 
                                                        type="button"
                                                        class="btn btn-soft-success" 
                                                        data-bs-toggle="button" 
                                                        @click="addTask" 
                                                        style="align-items: center;display: flex;gap: 4px;"
                                                    >
                                                        Додати <i class="bx bx-subdirectory-left fs-16"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </draggable>
                            </div>
                        </div>
                </div>

            </template>
        </draggable>

        <div class="tasks-list">
            <div>
                <div class="avatar-xs mb-3 copy" @click="showCreateBoard = true" >
                    <div class="avatar-title bg-success text-white fs-17 rounded" ><i class="ri-add-line" ></i></div>
                </div>
            </div>
        </div>

    </div>

    <!-- Створення колонки -->
    <createBoard 
        v-if="showCreateBoard"
        @close="showCreateBoard = false;getdata()"
        :objCard="objCard"
    />

    <!-- Перегляд картки завдання -->
    <viewTask 
        v-if="showTask"
        @close="showTask = false"
    />

</template>

<script>

import { VueDraggableNext } from 'vue-draggable-next';
import createBoard from './createBoard.vue'; // Створення колонки на дошку
import viewTask from './viewTask.vue' // Перегляд картки завдання
import { developmentProjects } from '@/API.js';

let apiServe = new developmentProjects();

export default {
    props: ["objCard"],
    components: {
        draggable: VueDraggableNext,
        createBoard,
        viewTask
    },
    data() {
        return {
            form: '',
            showCreateBoard: false, // створення колонки
            showBlockAddedTask: false, // блок створення таски
            showTask: false, // блок перегляду таски
            selectBlockAdded: "", // активна колонка
            newTaskContent: "", // вміст нового завдання
            workers: [
                {
                    "workerId": 1,
                    "workerName": "Борис"
                },
                {
                    "workerId": 2,
                    "workerName": "Даня"
                }
            ],
            columns: [
                {
                "id": "1",
                "name": "До виконання",
                "priority": "success",
                "color": "#95A5A6", 
                "tasks": [
                {
                    "id": "task-101",
                    "title": "Не додається товар у пос-терміналі",
                    "description": "Якийсь опис",
                    "priority": "high",
                    "ticket": "3145",
                    "tasks": [
                        {
                            "id": "task-201",
                            "title": "Не додається товар у пос-терміналі репорт 1",
                            "description": "",
                            "priority": "high",
                            "ticket": "3145",
                            "status": "До виконання",
                            "worker": "Maxim"
                        },
                        {
                            "id": "task-202",
                            "title": "Не додається товар у пос-терміналі репорт 2",
                            "description": "",
                            "priority": "high",
                            "ticket": "3145",
                            "status": "Тестується",
                            "worker": "Maxim"
                        }
                    ],
                    "users": [
                    ],
                    "key": "101"
                },
                {
                    "id": "task-102",
                    "title": "Не коректні відображення в розділі Клієнти(ios)",
                    "description": "Скласти прототип для нової сторінки сайту",
                    "priority": "medium",
                    "users": [
                        {
                            "workerId": 2,
                            "workerName": "Даня"
                        }
                    ],
                    "key": "102"
                }
                ]
            },
            {
                "id": "2",
                "name": "В роботі",
                "priority": "secondary",
                "color": "#3498DB",
                "tasks": [
                {
                    "id": "task-201",
                    "title": "Змінити дизайн обраної бонусної програми або знижки",
                    "description": "Реалізувати ендпоінти для обробки даних",
                    "priority": "high",
                    "users": [
                    
                    ],
                    "key": "201",
                    "tasksCount": {
                    "new": 90,
                    "all": 100,
                    "progressPercent": 90
                    }
                },
                {
                    "id": "task-202",
                    "title": "Не відцентрована кнопка сортування при виборі програми лояльності",
                    "description": "Реалізувати ендпоінти для обробки даних",
                    "priority": "high",
                    "users": [
                    
                    ],
                    "key": "201"
                }
                ]
            },
            {
                "id": "3",
                "name": "Тестування",
                "priority": "warning",
                "color": "#F39C12",
                "tasks": [
                {
                    "id": "task-301",
                    "title": "Додати можливість обирати групу клієнтів для окремо обраних клієнтів через меню Дія",
                    "description": "Завершити перевірку продукту перед релізом",
                    "priority": "low",
                    "users": [
                    
                    ],
                    "key": "401"
                }
                ]
            },
            {
                "id": "4",
                "name": "В передрелізі",
                "priority": "info",
                "color": "#F39C12",
                "tasks": [
                ]
            },
            {
                "id": "5",
                "name": "Завершено",
                "priority": "success",
                "color": "#1ABC9C",
                "tasks": [
                    {
                        "id": "task-501",
                        "title": "Додати поле Групи клієнтів в розділ Клієнти",
                        "description": "Виконано успішний реліз продукту",
                        "priority": "done",
                        "users": [],
                        "key": "501"
                    }
                ]
            }
            ]
        }       
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            // Отримання даних kanban

            apiServe.getProjectBoard(this.objCard.projectId).then(result => {
                if(result.status == 'done') {
                    console.log('res', result);
                    
                } else {

                }
            })
        },
        showTaskInput(columnName) {
            // Встановлення фокусу на поле вводу при створені завдання
            
            this.selectBlockAdded = columnName;
            this.$nextTick(() => {
                const input = this.$refs.taskInput;
                input[0].focus();
            });
        },
        addTask() {
            // Додавання завдання
            console.log("Завдання додано");
            this.newTaskContent = "";
            this.selectBlockAdded = null; // Закриваємо поле після додавання
            this.$toast.success("Завдання додано")
        },
        closeTaskBox() {
            // Закриваємо блок
            this.newTaskContent = "";
            this.selectBlockAdded = null;
        },
        onTaskChange(event) {
            console.log("Задача переміщена:", event);
        },
        onColumnChange(event) {
            console.log("Columns reordered:", this.columns);
        },
        addNewBoard(item){
            // Створення колонки 
            // console.log("addNewBoard", item);

            var newBoard = {
                id: 6,
                name: item.title,
                priority: "info",
                color: item.color,
                tasks: []
            }
            
            this.columns.push(newBoard)
            this.showCreateBoard = false;

        },
        getInitials(string) {
            // Отримання ініціалів
            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        },
        handleBlur(event) {
            // Перевірка, чи текстове поле порожнє
            if (!this.newTaskContent.trim()) {
                this.closeTaskBox(); // Закриваємо блок, якщо він порожній
            } else {
                this.addTask(); // Додаємо завдання
            }
        },
    }
}
</script>

<style scoped>
.tasks-box {
    width: 300px;
}
.title-task {
    white-space: normal;
}
.dragArea {
    height: 58vh;
    margin-bottom: 50px;
}
.subtask:hover {
    background: #e1edf55c;
}
/* Додаткові стилі для drag-and-drop */
.columns-container {
  display: flex;
  gap: 16px;
}
.tasks-list {
  width: 300px;
}
.dragArea {
  min-height: 100px;
}
</style>