<template>

    <div class="position-relative mx-n4 mt-n4">
        <div class="bg-wid-project" style="background: #0369a1;">
        </div>
    </div>
    <div class="pt-3">
        <b-row class="g-4">
            <!-- <b-col cols="auto">
            <div class="avatar-lg">
                <img src="@/assets/images/users/avatar-1.jpg" alt="user-img" class="img-thumbnail rounded-circle" />
            </div>
            </b-col> -->
            <b-col>
                <div class="p-2">
                    <div class="d-flex" style="align-items: center">
                        <div class="flex-grow-1">
                            <!--
                                Компонент skeleton - завантажувальний ефект

                                Параметри компонента:
                                - type="title": Скелетон буде стилізований як заголовок
                                - - type="avatar": Скелетон буде стилізований як аватар
                                - width="'200px'": Ширина скелетону
                                - height="'35px'": Висота скелетону
                                - color="'#005889'": Колір скелетону
                            -->
                            <skeleton 
                                v-if="isSkeleton"
                                type="title" 
                                :width="'200px'" 
                                :height="'35px'" 
                                :color="'#005889'" 
                            />
                            <h3 class="text-white mb-1 fw-semibold">{{ objCard.projectName }}</h3>
                        </div>
                        <div class="flex-shrink-0">
                            <div class="hstack gap-1 flex-wrap" >
                                <button type="button" class="btn avatar-xs mt-n1 p-0 favourite-btn active" @click="saveFavorite()">
                                    <span class="avatar-title bg-transparent fs-15">
                                        <i :class="`${objCard.favorite == true ? 'ri-star-fill' : 'ri-star-line'}`"></i>
                                    </span>
                                </button>
                                <button type="button" class="btn py-0 fs-18 text-white" id="settingDropdown" data-bs-toggle="dropdown">
                                    <i class="bx bx-dots-vertical-rounded"></i>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="settingDropdown">

                                    <li @click="showEditModal()">
                                        <!-- Кнопка редагування проекту todo -->
                                        <span class="dropdown-item copy">
                                            <i class="ri-pencil-fill align-bottom text-muted me-2"></i> {{ $t('toEditAp') }}
                                        </span>
                                    </li>

                                    <li >
                                        <!-- Редагування доступу до проекту todo -->
                                        <span class="dropdown-item copy" >
                                            <i class="ri-lock-line align-bottom text-muted me-2"></i> {{ $t('Access') }}
                                        </span>
                                    </li>

                                    <li>
                                        <!-- Видалення проекту todo -->
                                        <span class="dropdown-item copy" @click="dialogShow = true" >
                                            <i class="ri-delete-bin-5-line align-bottom text-muted me-2"></i> {{ $t('Delete') }}
                                        </span>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>

    <!-- 
        Модальне вікно підтвердження видалення події
    -->
    <modalremove 
        v-if="dialogShow" 
        @close="dialogShow = false" 
        @remove="remove()" 
    />

    <!-- Редагування проєкту -->
    <createProject 
        v-if="editShowBox"
        :objCard="objCard"
        :editPerm="true"
        @close="editShowBox = false;this.getdata()"
    />
    
</template>

<script>
import modalremove from '@/components/modal/modalremove' // компонент діалогового вікна видалення
import Skeleton from "../../../../components/skeleton/Skeleton.vue"; // Скелетон
import createProject from "../createProject.vue";
import { developmentProjects } from '@/API.js';
import { storeS } from '@/store' // глобальне сховище даних

let apiServe = new developmentProjects();

export default {
    props: ["objCard", "isSkeleton"],
    components: {
        modalremove,
        createProject,
        Skeleton
    },
    data() {
        return {
            form: "",
            star: false,
            dialogShow: false, // прапорець відображення видалення події
            editShowBox: false // прапорець відображення проєкту
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.objCard;
        },
        showEditModal(){
            this.editShowBox = true
        },
        saveFavorite(){
            /**
                * Додає/видаляє проєкт в обрані за допомогою API-запиту та виводить сповіщення про успішне Додавання або видалення.
                * favorite - true - проєкт в обраних
                * favorite - false - проєкт не в обраних
            */

            this.objCard.favorite = !this.objCard.favorite

            // delete this.form.projectId
            // delete this.form.projectKey
            // delete this.form.projectManagerId
            // delete this.form.projectManagerName
            // delete this.form.projectName

            // console.log("fd", this);
            
            apiServe.saveFavoriteProject(this.objCard.projectId, this.objCard).then(result => {
                if(result.status == 'done'){
                    if(this.objCard.favorite == true){
                        this.$toast.success(this.$t('ProjectAddedtoFavorites'))
                    } else {
                        this.$toast.success(this.$t('ProjectRemovetoFavorites'))
                    }
                } else {
                    this.errorSaveFavorite(result.error[0])
                }
            })
            
        },
        errorSaveFavorite(e){
            // відображення помилок

            switch (e) {
                case 'data not found':
                    this.$toast.error(this.$t('dataNotFoundSaveFavorite'))
                    break;
            
                default:
                    break;
            }
        },
        remove(){
            /**
                * Видаляє поточну проєкт за допомогою API-запиту та виводить сповіщення про успішне видалення.
                * 
            */
            
            apiServe.deleteProject(this.objCard.projectId).then(result => {
                if(result.status == 'done'){
                    this.dialogShow = false;
                    this.$toast.success(this.$t('Removed'));

                    this.$router.push({
                        name: "dev-projects", // Назва маршруту
                    });
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка видалення проєкту'; 
                    var statusRequest = result.status;
                    var api = 'deleteEvent';
                    var fileRequest = 'src/views/development/projects/project/header.vue';
                    var params = this.objCard.projectId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        }
    },
    computed: {
        preloader(){
            // статус завантаження
            return storeS.preloaderTable
        }
    }
}
</script>

<style scoped>
.bg-wid-project {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 180px;
    background: rgb(3, 105, 161);
}
.container .top .skeleton-container .skeleton {
  background-color: #aab0d0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.container .top .skeleton-container .skeleton::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
  animation: shimmer 2s infinite;
}

.container .top .skeleton-container .skeleton.skeleton-text {
  height: 10px;
  width: 100%;
  margin-bottom: 10px;
}

.container .top .skeleton-container .skeleton.skeleton-text:last-of-type {
  width: 80%;
}

.container .top .skeleton-container .skeleton.skeleton-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-bottom: 20px;
}

@keyframes shimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.container .content {
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container .content .title {
  font-weight: 700;
  margin: 0 0 8px 0;
}

.container .content .timestamp {
  font-size: .75rem;
  color: #B7B7B7;
}

.container .content .options {
  cursor: pointer;
}

.container .content .options::after {
  content: '\2807';
  font-size: 1.5rem;
  color: #B7B7B7;
}
</style>