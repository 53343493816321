<template>
    
    <dialogBox 
        :title="'Додати таб'"
        :classhead="'bg-white'"
        @close="this.$emit('close')"
    >
    
        <template v-slot:title>{{ $t('Add') }}</template>

        <template v-slot:body>

            <b-row>
                <b-col lg="12" class="mb-3">
                    <label for="boardName" class="form-label">Введіть назву табу</label>
                    <input type="text" class="form-control" id="boardName" placeholder="Вкажіть назву">
                </b-col>
                <b-col lg="12">
                    <label for="boardName" class="form-label">Виберіть колір</label>
                        <b-row>
                            <template v-for="item in colors" :key="item">
                                <b-col lg="1" class="mb-2 copy" @click="form.color = item.color">
                                    <div class="avatar-xs" >
                                        <div class="avatar-title rounded text-white" :style="`background-color: ${item.color};`"></div>
                                    </div>
                                </b-col>
                            </template>
                        </b-row>
                </b-col>
            </b-row>

            <div class="dropdown-divider mt-3 mb-4" ></div>

            <div class="my-2">
                <div class="row align-items-center">
                    <div class="col-lg-4">
                        <h5 class="fs-16">{{ $t('selectType') }}</h5>
                    </div>
                    <div class="col-lg-8">
                        <select
                            class="form-select mb-3"
                        >
                            <option v-for="val in listTypes" :key="val" :value="val" >{{val.title}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="dropdown-divider" ></div>

            <div class="mt-4">
                <div class="row align-items-center">
                    <div class="col-lg-4">
                        <h5 class="fs-16">{{ $t('Status') }}</h5>
                    </div>
                    <div class="col-lg-8">
                        <select
                            class="form-select mb-3"
                        >
                            <option v-for="val in listValue" :key="val" :value="val" >{{val.title}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="dropdown-divider" ></div>

            <div class="mt-4">
                <div class="row align-items-center">
                    <div class="col-lg-4">
                        <h5 class="fs-16">{{ $t('decidedStatus') }}</h5>
                    </div>
                    <div class="col-lg-8">
                        <select
                            class="form-select mb-3"
                        >
                            <option v-for="val in decidedStatusList" :key="val" :value="val" >{{val.title}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="dropdown-divider" ></div>

            <div class="mt-4">
                <div class="row align-items-center">
                    <div class="col-lg-4">
                        <h5 class="fs-16">{{ $t('priority') }}</h5>
                    </div>
                    <div class="col-lg-8">
                        <select
                            class="form-select mb-3"
                        >
                            <option v-for="val in listPriority" :key="val" :value="val" >{{val.title}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="dropdown-divider" ></div>

            <div class="mt-4">
                <div class="row align-items-center">
                    <div class="col-lg-4">
                        <h5 class="fs-16">{{ $t('added') }}</h5>
                    </div>
                    <div class="col-lg-8">
                        <select
                            class="form-select mb-3"
                        >
                            <option v-for="val in listWorkers" :key="val" :value="val" >{{val.title}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="dropdown-divider" ></div>

            <div class="mt-4">
                <div class="row align-items-center">
                    <div class="col-lg-4">
                        <h5 class="fs-16">{{ $t('inJobWorker') }}</h5>
                    </div>
                    <div class="col-lg-8">
                        <select
                            class="form-select mb-3"
                        >
                            <option v-for="val in listWorkers" :key="val" :value="val" >{{val.title}}</option>
                        </select>
                    </div>
                </div>
            </div>

        </template>
        
    
    
    </dialogBox>

</template>

<script>
// import dialogBox from '@/components/modal/modal-lg'
import dialogBox from '@/components/modal/dialog'
import { storeS } from '@/store'

export default {
    props: [],
    components: {
        dialogBox
    },
    data() {
        return {
            form: '',
            listTypes: [
                { title: this.$t('all'), value: '' },
                { title: this.$t('problem_to_develop'), value: 'problem' },
                { title: this.$t('wish_to_develop'), value: 'wishes' },
            ],
            listValue: [
                { title: this.$t('new'), value: 'new' },
                { title: this.$t('statusInWork'), value: 'jobs' },
                { title: this.$t('statusCompleted'), value: 'decided' },
            ],
            listPriority: [
                { title: this.$t('Critical'), value: '3' },
                { title: this.$t('priorityHigh'), value: '2' },
                { title: this.$t('priorityMedium'), value: '1' },
                { title: this.$t('priorityLow'), value: '0' },
            ],
            decidedStatusList: [
                { title: this.$t('waitTesting'), value: 'waittesting' },
                { title: this.$t('waitRelease'), value: 'waitrelease' },
                { title: this.$t('inRelease'), value: 'in_release' },
                { title: this.$t('noProblem'), value: 'noProblem' },
            ],
            listWorkers: [],
            colors: [
                { color: '#1ABC9C', title: '1ABC9C' },
                { color: '#3498DB', title: '3498DB' },
                { color: '#0369a1', title: '0369a1' },
                { color: '#F39C12', title: 'F39C12' },
                { color: '#E74C3C', title: 'E74C3C' },
                { color: '#34495E', title: '34495E' },
                { color: '#95A5A6', title: '95A5A6' },
                { color: '#607D8B', title: '607D8B' },
                { color: '#9B59B6', title: '9B59B6' },
            ],
        }
    },
    created(){
        for(var item in this.workers){
            this.listWorkers.push({
                title: this.workers[item].workerName,
                value: this.workers[item].workerId
            })
        }
    },
    computed: {
        workers(){
            return storeS.workers
        }
    }
}
</script>