<!-- тікети -->

<template>
    
    <!-- 
      Компонент таблиці 
      @search - дія пошуку
      @addCategory - дія додавання табу
      @removeCategory - дія видалення табу
      @create - дія створення тікету
      @open - дія відкриття тікету
      @changeFilter - дія зміни фільтру (табу)
      @clearFilter - дія очистки фільтру
      @remove - дія видалення тікету
      @getdata - дія отримання даних по тікетам
      @changelimit - зміна кількості відображення тікетів
      @changeTab - зміна табу
      :dataParams - пропс базових параметрів
      :objParams - пропс передачі парметрів (фільтрів, що будуть доступні)
      :columns - прпос передачі колонок
      :rows - пропс передачі самих тікетів
      :pages - пропс передачі номеру сторінки
      :showpag - пропс показування пагінації
      :tabs - пропс, що передає інформацію про таби
      :addtabs - дозвіл на додавання своїх табів
      :createdform - пропс на додавання тікетів
    -->
    <tablecustom 
        @search="searchB"
        @open="open" 
        @changeFilter="changeFilter" 
        @clearFilter="clearFilter" 
        @getdata="getdata"
        @changelimit="changelimit"
        @changeTab="changeTab"
        @changeWithClearFilter="changeWithClearFilter"
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="showpag"
        @sortColumn="sortColumn" 
        :addtabs="true"
        @addCategory="showFilterBox = true"
        :tabs="othertabs"
    />

    <filterTabs 
        v-if="showFilterBox" 
        @close="showFilterBox = false"
        @changeTabs="changeTabs"
        :othertabs="othertabs"
    />

</template>

<script>
import tablecustom from '@/components/globaltable/index';
// import createBox from '../newtickets/createticket'
// import viewbox from '../newtickets/view/index.vue'
// import Multiselect from "@vueform/multiselect";
// import "@vueform/multiselect/themes/default.css";
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { mutateTypeTicket, 
        mutateStatusTicket,
        mutateDecidedStatusTicket,
        mutatePriorityTicket,
        mutateProblemEnv,
        nameTag
      } from '@/usabilityScripts/globalMutate.js'
import { Tickets } from '@/API.js';
import { storeS } from '@/store'
import filterTabs from './filterTabs.vue';

let apiServe = new Tickets();

export default {
    props: ["objCard", "type"],
    components: {
        tablecustom,
        // viewbox,
        filterTabs
    },
    data() {
        return {
            form: '',
            showpag: false,
            dataParams: {
                status: false,
                page: "tickets"
            },
            objParams:{
                page: '1',
                pagelimit: '25',
                search: '',
                priority: '',
                status: '',
                decidedStatus: '',
                pr1: '',
                pr2: '',
                // tags: '',
                of: 'create_datetime',
                ot: 'desc'
            },
            rows: [],
            columns: [
                {
                    name: "ID",
                    text: "ticketId",
                    align: "left",
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("ticket_name"),
                    text: "ticketName",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutateTicketName(item),
                    width: '10'
                },
                // {
                //   name: this.$t("description"),
                //   text: "createComment",
                //   align: "left",
                //   status: false
                // },
                {
                    name: this.$t("priority"),
                    text: "priority",
                    align: "right",
                    mutate: (item) => this.mutatepriority(item).name,
                    mutateClass: (item) => this.mutatepriority(item).color,
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("added"),
                    text: "createWorkerId",
                    align: "right",
                    mutate: (item) => this.nameWorker(item),
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("date"),
                    text: "createDatetime",
                    align: "right",
                    status: true,
                    sort: 'create_datetime',
                    sortType: 'asc',
                    width: '10'
                },
                {
                    name: this.$t("Status"),
                    text: "status",
                    align: "right",
                    mutate: (item) => this.mutatestatus(item).name,
                    mutateClass: (item) => this.mutatestatus(item).color,
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("decidedStatus"),
                    text: "decidedStatus",
                    align: "right",
                    mutate: (item) => this.mutateDecidedStatus(item).name,
                    mutateClass: (item) => this.mutateDecidedStatus(item).color,
                    status: true,
                    width: '10'
                },
            ],
            showFilterBox: false,
            othertabs: [],
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(page){
            this.showpag = true

            this.objParams.page = page != undefined ? page : '1';

            this.objParams.type = this.type;
            this.objParams.problemEnvironment = this.objCard.projectKey;

            apiServe.getTickets(this.objParams).then(result => {
                if(result.status === 'done') {
                    this.objPages = result.data;
                    this.rows = result.data.items;  
                } else {
                    this.$toast.error(this.$t('error') + ` #300`);
                }
            }).catch(err => {
                this.$toast.error(this.$t('error') + ` # 300`);
            })
        },
        // пошук
        searchB(e){
            this.objParams.search = e
            this.getdata();
        },
        changeFilter(name, value){
            console.log(...arguments)
            if(name == 'all'){
                this.objParams.search = '',
                this.objParams.priority = '',
                this.objParams.status = '',
                this.objParams.decidedStatus = '',
                this.objParams.workerId = '',
                this.objParams.inJobWorkerId = '',
                this.objParams.pr1 = '',
                this.objParams.pr2 = '';
                this.objParams.tags = '';
                this.getdata();
            } else if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else if(name == 'tags'){
                    this.objParams[name] = value[0] == "" ? '' : JSON.stringify(value); 
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            } else {
                this.objParams[name] = value;
            }
        },
        // змінити ліміт і оновити дані
        changelimit: function(limit){
            this.objParams.pagelimit = limit;
            this.getdata();
        },
        // скорочує назву тікету
        mutateTicketName(e) {
            let name = ''
            if(e.length > 50) {
                name = e.slice(0, 46) + '...'
            } else {
                name = e
            }
            return name
        },
        // мутейт співробітника
        nameWorker(item){
            return nameWorker(item)
        },
        mutateticket(e){
        // мутейт типу тікета
            return mutateTypeTicket(e)
        },
        mutatestatus(e){
        // мутейт статусу
            return mutateStatusTicket(e)
        },
        mutateDecidedStatus(e){
        // мутейт типу статусу, коли тікет "Виконано"
            return mutateDecidedStatusTicket(e)
        },
        mutatepriority(e){
        // мутейт пріорітету
            return mutatePriorityTicket(e)
        },
        mutateProblemEnv(e){
        // мутейт середовища проблеми
            return mutateProblemEnv(e)
        },
    },
    computed: {
        perms(){
            return storeS.perms
        },
        workers() {
            return storeS.workers
        },
        checks() {
            return storeS.checks
        },
        user(){
            return storeS.userbase
        }
    },
}
</script>