<template>

  <!--
    Контейнер для скелетону, який динамічно застосовує класи та стилі на основі переданих пропсів.
    - :class="['skeleton', skeletonType]": Додає класи 'skeleton' та тип скелетону (наприклад, 'skeleton-text' або 'skeleton-avatar').
    - :style="{ width: width, height: height, background: color }": Динамічно задає стиль для ширини, висоти та кольору.
  -->
  <div 
    :class="['skeleton', skeletonType]" 
    :style="{ width: width, height: height, background: color }"
  ></div>

</template>

<script>
export default {
  name: 'Skeleton',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    width: {
      type: String,
      default: "200px"
    },
    height: {
      type: String,
      default: "35px"
    },
    color: {
      type: String,
      default: '#aab0d0'
    }
  },
  computed: {
    skeletonType() {
      return `skeleton-${this.type}`;
    },
  },
};
</script>

<style scoped>
.skeleton {
  background-color: #aab0d0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%);
  animation: shimmer 2s infinite;
}

.skeleton-text {
  height: 10px;
  margin-bottom: 10px;
}

.skeleton-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 20px;
}

@keyframes shimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
</style>
